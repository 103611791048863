import React, { lazy, useMemo, useState } from 'react';
import { SideLayout, WidgetWrapper } from '../../components/common.styles';
import { WidgetSetupComponent } from '../../components/widget-setup-section/widget-setup.component';
import { WidgetSettings } from '../../components/widget-settings/widget-settings.component';
import { ReviewsSummaryPreview, ReviewsSummaryUser } from '../../components/reviews-summary/reviews-summary.component';
import mockData from '../../mock-data-in-n-out.json';
import { ReviewsPreviewType } from '../../types/review-data.type';
import { SettingsType } from '../../types/settings.type';
import { ReviewSource } from '../../types/review-source.enum';
import { LayoutType } from '../../types/layout-type.enum';
import { EditableTitle } from '../../components/editable-title/editable-title.component';
import { themeMui, themeMuiDark, themeStyledComponents, themeStyledComponentsDark } from '../../theme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { DEFAULT_GOOGLE_COMPANY_ID } from '../../constants';
const ReviewsCarouselComponent = lazy(() => import('../../components/reviews-carousel/reviews-carousel.component'));
const ReviewsMasonryGridComponent = lazy(() => import('../../components/reviews-masonry-grid/reviews-masonry-grid.component'));
const ReviewsWallComponent = lazy(() => import('../../components/reviews-wall/reviews-wall.component'));
interface Props {
    googleCompanyId: string;
    onGoogleCompanyIdChange: (value: string) => void;

    yelpAlias: string;
    onYelpAliasChange: (value: string) => void;

    facebookAlias: string;
    onFacebookAliasChange: (value: string) => void;

    trustpilotAlias: string;
    onTrustpilotAliasChange: (value: string) => void;

    settings: SettingsType;
    onSettingsChange: (value: SettingsType) => void;
    onSubmit: () => void;
    onLoginClicked: () => void;
    previewData: ReviewsPreviewType;
    onPreviewDataChange: (value: ReviewsPreviewType) => void;
}

export const WidgetSetupPage = ({
    googleCompanyId,
    onGoogleCompanyIdChange,
    yelpAlias,
    onYelpAliasChange,
    facebookAlias,
    onFacebookAliasChange,
    trustpilotAlias,
    onTrustpilotAliasChange,
    settings,
    onSettingsChange,
    onSubmit,
    onLoginClicked,
    previewData,
    onPreviewDataChange,
}: Props) => {
    const [source, setSource] = useState(ReviewSource.google);
    const items = useMemo(
        () => previewData?.reviews?.filter((el) => el.rating >= settings.minRating && (el.text || el.videoUrl)),
        [previewData?.reviews]
    );
    return (
        <>
            <WidgetSetupComponent
                onChange={(value) => {
                    if (value.googleCompanyId) {
                        onGoogleCompanyIdChange(value.googleCompanyId);
                        onYelpAliasChange('');
                        onFacebookAliasChange('');
                        onTrustpilotAliasChange('');
                    } else if (value.yelpAlias) {
                        onGoogleCompanyIdChange('');
                        onFacebookAliasChange('');
                        onTrustpilotAliasChange('');
                        onYelpAliasChange(value.yelpAlias);
                    } else if (value.facebookAlias) {
                        onGoogleCompanyIdChange('');
                        onYelpAliasChange('');
                        onTrustpilotAliasChange('');
                        onFacebookAliasChange(value.facebookAlias);
                    } else if (value.trustpilotAlias) {
                        onGoogleCompanyIdChange('');
                        onYelpAliasChange('');
                        onFacebookAliasChange('');
                        onTrustpilotAliasChange(value.trustpilotAlias);
                    }
                    onPreviewDataChange(value.previewData);
                }}
                onSubmit={onSubmit}
                source={source}
            />
            <SideLayout>
                <WidgetSettings
                    value={settings}
                    onChange={onSettingsChange}
                    sources={[source]}
                    onSourceChange={setSource}
                    rounded
                />
                <StyledThemeProvider theme={settings.isDark ? themeStyledComponentsDark : themeStyledComponents}>
                    <ThemeProvider theme={settings.isDark ? themeMuiDark : themeMui}>
                        <WidgetWrapper
                            className={settings.isDark && 'wrapper-dark'}
                            $centerCorrection={settings.layout === LayoutType.carousel}
                        >
                            {settings.showTitle && <EditableTitle clickable value={settings.title} />}
                            {settings.layout === LayoutType.carousel && (
                                <ReviewsCarouselComponent
                                    items={items}
                                    autoplay={settings.autoplay}
                                    accentColor={settings.accentColor}
                                    aiHighlight
                                    isProPlan
                                    waitUntilPageLoad
                                />
                            )}
                            {settings.layout === LayoutType.masonry && (
                                <ReviewsMasonryGridComponent
                                    items={items}
                                    accentColor={settings.accentColor}
                                    aiHighlight
                                    isProPlan
                                />
                            )}
                            {settings.layout === LayoutType.list && (
                                <ReviewsWallComponent items={items} accentColor={settings.accentColor} aiHighlight isProPlan />
                            )}
                            {settings.showSummary && (
                                <>
                                    {
                                        // @ts-ignore
                                        previewData?.isMock ? (
                                            <ReviewsSummaryUser
                                                googleRating={mockData.rating}
                                                googleRatingsTotal={mockData.ratingsTotal}
                                                googleCompanyId={DEFAULT_GOOGLE_COMPANY_ID}
                                                // @ts-ignore
                                                yelpAlias={mockData.yelpAlias}
                                                // @ts-ignore
                                                yelpRating={mockData.yelpRating}
                                                // @ts-ignore
                                                yelpRatingsTotal={mockData.yelpRatingsTotal}
                                                facebookAlias={mockData.facebookAlias}
                                                facebookRating={mockData.facebookRating}
                                                facebookRatingsTotal={mockData.facebookRatingsTotal}
                                                trustpilotAlias={mockData.trustpilotAlias}
                                                trustpilotRating={mockData.trustpilotRating}
                                                trustpilotRatingsTotal={mockData.trustpilotRatingsTotal}
                                            />
                                        ) : (
                                            <ReviewsSummaryPreview
                                                rating={previewData?.rating || mockData.rating}
                                                ratingsTotal={previewData?.ratingsTotal || mockData.ratingsTotal}
                                                googleCompanyId={googleCompanyId || DEFAULT_GOOGLE_COMPANY_ID}
                                                yelpAlias={yelpAlias}
                                                trustpilotAlias={trustpilotAlias}
                                                facebookAlias={facebookAlias}
                                            />
                                        )
                                    }
                                </>
                            )}
                        </WidgetWrapper>
                    </ThemeProvider>
                </StyledThemeProvider>
            </SideLayout>
            {/*<LoginButton onClick={() => {
                document.dispatchEvent(new Event('login'))
            }}/>*/}
        </>
    );
};

export default WidgetSetupPage;
