import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Background } from '../../assets/pro-plan-bg.svg';
import { ReactComponent as LogoIcon } from '../../assets/logo-gradient.svg';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { SpaceButton } from '../../components/space-button/space-button.component';
import {
    BillingLabel,
    BulletRow,
    FeaturesContainer,
    PerText,
    PlanWrapper,
    PricingText,
    PricingTextCrossed,
} from './account-settings.styles';
import { BillingCycleType } from '../../types/billing-cycle.type';
import { MONTHLY_ESSENTIAL_PRICE_ID, YEARLY_ESSENTIAL_PRICE_ID } from '../../constants';

export const EssentialPlanCard = ({
    active,
    onClick,
    loading,
    billingCycle,
}: {
    active?: boolean;
    loading: boolean;
    onClick: (priceId: string) => void;
    billingCycle: BillingCycleType;
}) => (
    <PlanWrapper>
        <PlanContainer>
            <BgContainer>
                <Background />
            </BgContainer>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: '30px' }}>
                <LogoIcon />
            </Stack>
            <Stack gap={2}>
                <Typography sx={{ fontSize: '24px', fontWeight: 500, color: '#fff' }}>Essential Plan</Typography>
                <Typography sx={{ fontSize: '16px', color: '#71708C', height: '72px' }}>
                    Includes unlimited display of reviews, images, management and multiple reviews sources.
                </Typography>
            </Stack>
            <Stack gap={1}>
                <Stack direction="row" alignItems="flex-end" gap={0.5}>
                    {billingCycle === 'year' && <PricingTextCrossed>$ 29</PricingTextCrossed>}
                    <PricingText>{billingCycle === 'year' ? '$ 19' : '$ 29'}</PricingText>
                    <PerText>/month</PerText>
                </Stack>
                <BillingLabel>Billed {billingCycle}ly</BillingLabel>
            </Stack>
            <SpaceButton
                disabled={active || loading}
                variant="secondary"
                onClick={() => {
                    if (!active) {
                        onClick(billingCycle === 'year' ? YEARLY_ESSENTIAL_PRICE_ID : MONTHLY_ESSENTIAL_PRICE_ID);
                    }
                }}
            >
                {active ? <>Your current plan</> : <>Choose this plan</>}
            </SpaceButton>
        </PlanContainer>
        <FeaturesContainer>
            <BulletRow label="Unlimited review platforms" />
            <BulletRow label="Unlimeted reviews are displayed in the website widget" />
            <BulletRow label="No watermark" />
            <BulletRow label="Display review images in the widget" />
            <BulletRow label="Pin important reviews" />
            <BulletRow label="AI-powered review summary highlights" />
            <Stack width="fit-content" borderRadius={46} sx={{ padding: '4px 12px', background: '#F7F8FA' }}>
                <Typography fontSize="14px" fontWeight="500">
                    & FREE FEATURES
                </Typography>
            </Stack>
        </FeaturesContainer>
    </PlanWrapper>
);

const PlanContainer = styled.div<{ $logoGrey?: boolean }>`
    position: relative;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: #101220;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: hidden;
    height: fit-content;
    width: 100%;
    flex-shrink: 0;
`;

const BgContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
`;
