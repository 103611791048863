import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { SubsectionTitle, SubsectionWrapper } from '../common.styles';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReviewSource } from '../../types/review-source.enum';
import { ProfilePlatformCard } from '../platforms-manage/profile-platform-card.component';
import { AddPlatformModal } from './add-platform.modal';
import { UserPopulatedType } from '../../types/user-data.type';
import { ReviewsPreviewType } from '../../types/review-data.type';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { LockIcon } from '../lock-icon.component';

interface Props {
    userPopulatedData: UserPopulatedType;
    onPreviewDataChange: (previewData?: ReviewsPreviewType) => void;
    onUserPopulatedDataChange: (data: UserPopulatedType) => void;
    platformPreviewData: ReviewsPreviewType;
    isProPlan: boolean;
    isEssentialPlan: boolean;
    onPaidFeatureOpen: () => void;
}

const calculateSources = (userPopulatedData: UserPopulatedType) =>
    [
        userPopulatedData.user.googleCompanyId && ReviewSource.google,
        userPopulatedData.user.yelpAlias && ReviewSource.yelp,
        userPopulatedData.user.facebookAlias && ReviewSource.facebook,
        userPopulatedData.user.trustpilotAlias && ReviewSource.trustpilot,
    ].filter((el) => el);

export const ProfilePlatformsComponent = ({
    userPopulatedData,
    onPreviewDataChange,
    onUserPopulatedDataChange,
    platformPreviewData,
    isProPlan,
    isEssentialPlan,
    onPaidFeatureOpen,
}: Props) => {
    const [addPlatformOpen, setAddPlatformOpen] = useState(false);
    const [sources, setSources] = useState(calculateSources(userPopulatedData));

    const deletePlatform = async (source: ReviewSource) => {
        try {
            setSources(sources.filter((el) => el !== source));
            if (source === ReviewSource.google) {
                onUserPopulatedDataChange({
                    ...userPopulatedData,
                    user: { ...userPopulatedData.user, googleCompanyId: null },
                    reviews: userPopulatedData.reviews.filter((el) => el.source !== ReviewSource.google),
                });
                if (platformPreviewData) {
                    onPreviewDataChange({
                        ...platformPreviewData,
                        googleCompanyId: null,
                        reviews: platformPreviewData.reviews.filter((el) => el.source !== ReviewSource.google),
                    });
                }
            } else if (source === ReviewSource.yelp) {
                onUserPopulatedDataChange({
                    ...userPopulatedData,
                    user: { ...userPopulatedData.user, yelpAlias: null },
                    reviews: userPopulatedData.reviews.filter((el) => el.source !== ReviewSource.yelp),
                });
                if (platformPreviewData) {
                    onPreviewDataChange({
                        ...platformPreviewData,
                        yelpAlias: null,
                        reviews: platformPreviewData.reviews.filter((el) => el.source !== ReviewSource.yelp),
                    });
                }
            } else if (source === ReviewSource.facebook) {
                onUserPopulatedDataChange({
                    ...userPopulatedData,
                    user: { ...userPopulatedData.user, facebookAlias: null },
                    reviews: userPopulatedData.reviews.filter((el) => el.source !== ReviewSource.facebook),
                });
                if (platformPreviewData) {
                    onPreviewDataChange({
                        ...platformPreviewData,
                        facebookAlias: null,
                        reviews: platformPreviewData.reviews.filter((el) => el.source !== ReviewSource.facebook),
                    });
                }
            } else if (source === ReviewSource.trustpilot) {
                onUserPopulatedDataChange({
                    ...userPopulatedData,
                    user: { ...userPopulatedData.user, trustpilotAlias: null },
                    reviews: userPopulatedData.reviews.filter((el) => el.source !== ReviewSource.trustpilot),
                });
                if (platformPreviewData) {
                    onPreviewDataChange({
                        ...platformPreviewData,
                        trustpilotAlias: null,
                        reviews: platformPreviewData.reviews.filter((el) => el.source !== ReviewSource.trustpilot),
                    });
                }
            }

            const token = localStorage.getItem('access_token');
            if (token) {
                await axios.post(
                    `${SERVER_URL}/user/remove-source`,
                    { source },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
            }
        } catch (e) {
            console.error(e);
        }
    };

    const addPlatformDisabled = !isProPlan && !isEssentialPlan && sources.length > 0;

    return (
        <>
            <SubsectionWrapper>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                    <SubsectionTitle>Platforms</SubsectionTitle>
                    <Tooltip
                        placement="top"
                        disableHoverListener={!addPlatformDisabled}
                        title={
                            <Typography fontSize="14px" fontWeight="500">
                                PRO Feature
                            </Typography>
                        }
                    >
                        <Button
                            variant="outlined"
                            color="info"
                            startIcon={
                                addPlatformDisabled ? (
                                    <Stack sx={{ pb: 1 }}>
                                        <LockIcon />
                                    </Stack>
                                ) : (
                                    <PlusIcon />
                                )
                            }
                            onClick={() => {
                                if (!addPlatformDisabled) {
                                    setAddPlatformOpen(true);
                                } else {
                                    onPaidFeatureOpen();
                                }
                            }}
                            size="small"
                        >
                            Add
                        </Button>
                    </Tooltip>
                </Stack>
                {sources.length ? (
                    <Stack gap={1}>
                        {sources.map((source) => (
                            <ProfilePlatformCard key={source} platform={source} onDelete={() => deletePlatform(source)} />
                        ))}
                    </Stack>
                ) : (
                    <Stack sx={{ py: 2 }} gap={1} alignItems="center">
                        <Typography fontSize="18px">🫢</Typography>
                        <Typography fontSize="14px" fontWeight="500" sx={{ color: 'grey.main' }}>
                            Oops! Add platforms to get started.
                        </Typography>
                    </Stack>
                )}
            </SubsectionWrapper>
            <AddPlatformModal
                sources={sources}
                open={addPlatformOpen}
                userPopulatedData={userPopulatedData}
                onClose={() => {
                    setAddPlatformOpen(false);
                }}
                onAdded={(source, previewData) => {
                    onPreviewDataChange(previewData);
                    setSources([...sources, source]);
                    setAddPlatformOpen(false);
                }}
            />
        </>
    );
};
